module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag-cookieconsent/gatsby-browser.js'),
      options: {"plugins":[],"cookieConsentConfig":{"categories":{"necessary":{"enabled":true,"readOnly":true},"analytics":{}},"guiOptions":{"consentModal":{"layout":"box","position":"bottom left"}},"language":{"default":"pt-br","translations":{"pt-br":{"consentModal":{"title":"Nós usamos cookies","description":"Para continuar navegando defina suas preferências","acceptAllBtn":"Aceitar todos","acceptNecessaryBtn":"Rejeitar todos","showPreferencesBtn":"Gerenciar preferências individualmente"},"preferencesModal":{"title":"Gerenciar preferências de cookies","acceptAllBtn":"Aceitar todos","acceptNecessaryBtn":"Rejeitar todos","savePreferencesBtn":"Aceitar selecionados","closeIconLabel":"Fechar modal","sections":[{"title":"Gerencie aqui suas preferências de cookies","description":"Nós respeitamos as suas preferências e iremos seguir de acordo com a configuração que você nos informar."},{"title":"Cookies estritamente necessários","description":"Esses cookies são essenciais para o funcionamento do site e não podem ser desabilitados.","linkedCategory":"necessary"},{"title":"Performance e Analytics","description":"Esses cookies coletam informações sobre como você usa o website.","linkedCategory":"analytics"}]}}}}},"enableForAllEnvironments":false,"googleGtagPluginConfig":{"trackingIds":["GTM-5FCVWFW6"],"gtagConfig":{"cookie_flags":"max-age=7200;secure;samesite=none","anonymize_ip":false}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
